import React from "react";

import Advantages from "./components/Advantages";

import Contact from "@sections/Contact";
import DefaultSection from "@sections/DefaultSection";
import InfoSection from "@sections/InfoVerticalSection";
import SliderSection from "@sections/SliderSection";
import Header from "@layouts/Header";
import Reason from "@components/Reason";
import Faq from "@components/Faq";

import "./Services.styles.scss";

const ServicesTemplate = ({ data }) => {
  const header = data?.header || {};
  const service = data?.service || {};
  const sectionTwo = data?.sectionTwo || {};
  const sectionThree = data?.sectionThree || {};
  const reasons = data?.reasons || {};
  const partner = data?.partner || {};
  const timeAndResources = data?.timeAndResources || {};
  const customerJourney = data?.customerJourney || {};
  const faq = data?.faq || {};
  const toprated = data?.toprated || {};
  const customers = data?.customers || {};
  const contact = data?.contact || {};

  return (
    <div className="services">
      <Header {...header} />

      <DefaultSection
        info={{
          ...service,
        }}
        rightSide={<Advantages items={service?.items} />}
        isVertical
      />
      <InfoSection {...sectionTwo} hasBackground />
      <DefaultSection
        info={{
          ...sectionThree,
        }}
        image={sectionThree?.image}
        isReverse
      />
      <DefaultSection
        info={{
          ...reasons,
        }}
        hasBackground
        rightSide={
          <div className="reasons">
            {reasons?.reasons?.map((item = {}, step) => {
              return <Reason {...item} step={step + 1} />;
            })}
          </div>
        }
        isVertical
      />
      <DefaultSection
        info={{
          ...partner,
          button: partner.buttonText,
        }}
        image={partner?.image}
        isReverse
      />
      <InfoSection {...timeAndResources} hasBackground />
      <DefaultSection
        info={{
          ...customerJourney,
          button: customerJourney?.buttonText,
        }}
        image={customerJourney?.image}
      />
      <Faq data={faq} />
      <DefaultSection
        info={{
          ...toprated,
          button: toprated?.buttonText,
        }}
        image={toprated?.image}
      />
      <SliderSection {...customers} />
      <Contact
        info={{
          ...contact,
          isWhite: true,
        }}
      />
    </div>
  );
};

export default ServicesTemplate;
