import React from "react";

import "./Reason.styles.scss";

const Reason = ({ title, description, step }) => {
  return (
    <div className="reason">
      <div className="reason__icon">{step}</div>
      <div className="reason__title">{title}</div>
      <div className="reason__description">{description}</div>
    </div>
  );
};

export default Reason;
