import React from "react";
import Advantage1 from "@components/svg/Advantages1";
import Advantage2 from "@components/svg/Advantages2";
import Advantage3 from "@components/svg/Advantages3";
import Advantage4 from "@components/svg/Advantages4";
import Advantage from "@components/Advantage";
import "./Advantages.styles.scss";

const icons = [Advantage1, Advantage2, Advantage3, Advantage4];

const Advantages = ({ items }) => {
  return (
    <div className="advantages">
      <div className="advantages__inner container">
        <div className="advantages__icons">
          {items?.map((item, key) => {
            return (
              <Advantage Icon={icons?.[key]} title={item?.title} />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Advantages;
