import React from "react";
import Layout from "@layouts/Layout";

import ServicesTemplate from "./Services";

const ServicesPage = ({ data: initialData }) => {
  const data = initialData?.markdownRemark?.frontmatter;

  const seo = {
    ...(data?.seo || {}),
    image: data?.seo?.image?.childImageSharp?.fluid?.src,
  };

  return (
    <Layout seo={seo}>
      <ServicesTemplate data={data} />
    </Layout>
  );
};

export default ServicesPage;

export const servicesPageQuery = graphql`
  query ServicesPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "Services" } }) {
      frontmatter {
        seo {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        header {
          title
          description
          buttonText
          subTitle
          video {
            name
            publicURL
          }
        }
        service {
          category
          title
          description
          items {
            title
          }
        }
        sectionTwo {
          title
          description
        }
        sectionThree {
          category
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        reasons {
          category
          title
          description
          reasons {
            title
            description
          }
        }
        partner {
          category
          title
          description
          buttonText
          buttonLink
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        timeAndResources {
          category
          title
          description
        }
        customerJourney {
          category
          title
          description
          buttonText
          buttonLink
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        faq {
          question
          answer
        }
        toprated {
          category
          title
          description
          buttonText
          buttonLink
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        customers {
          category
          title
          description
          items {
            description
            author {
              fullname
              company
              image {
                childImageSharp {
                  fluid(maxWidth: 2048, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        contact {
          category
          title
          description
        }
      }
    }
  }
`;
